import React, { useState } from "react";
import { BadgeCheckIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import axios from 'axios';
const FormData = require('form-data');

const UploadForm = () => {
    const [name, setName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [validationResult, setValidationResult] = useState(null);

    const submitForm = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        const url = `https://zl-dev-vaccination-approval.azurewebsites.net/api/HTTPImageOCR?code=ARj6WJOEfmciLtzpI9Dg/xkB2eI7GldcRXwCc4GuRtesPR9gITiP4g==&name=${name}`;

        axios
            .post(url, formData)
            .then((res) => {
                setValidationResult(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setValidationResult(err.response.data ? err.response.data : { isValid: false });
                setLoading(false);
            });
    };

    const displayValidationResult = () => {
        const val = validationResult;
        return (
            <div>
                <div className="bg-white pb-5 border-b border-gray-100">
                    <div className="-ml-4 -mt-2 flex items-center justify-center flex-wrap">
                        <div className="mt-2 flex-shrink-0">
                            {val.isValid ? <CheckCircleIcon className="text-green-500 h-16 w-16" /> : <XCircleIcon className="text-red-500 h-16 w-16" />}
                        </div>
                        <div className="ml-4 mt-2">
                            <h3 className="text-2xl leading-6 font-semibold text-gray-900">
                                {val.isValid ? <span>Record is valid.</span> : <span>Record is not valid.</span>}
                            </h3>
                        </div>
                    </div>
                </div>

                {val.isValid ?
                    <div className="bg-white pb-6 mb-6 mt-5 border-b border-gray-100">
                        <div className="-ml-4 -mt-2 flex items-center justify-left flex-wrap">
                            <div className="ml-4 mt-2">
                                <h3 className="leading-6 font-semibold text-gray-900">
                                    Evidence type: <span className="font-normal">{val.evidenceType}</span>
                                </h3>
                            </div>
                        </div>

                        {val.vaccine ? <div className="-ml-4 flex items-center justify-left flex-wrap">
                            <div className="ml-4 mt-2">
                                <h3 className="leading-6 font-semibold text-gray-900">
                                    Vaccine: <span className="font-normal">{val.vaccine}</span>
                                </h3>
                            </div>
                        </div> : <React.Fragment />}

                        <div className="-ml-4 flex items-center justify-left flex-wrap">
                            <div className="ml-4 mt-2">
                                <h3 className="leading-6 font-semibold text-gray-900">
                                    Double vaccinated:
                                </h3>
                            </div>
                            <div className="mt-2 flex-shrink-0">
                                {val.doubleVaccinated ? <CheckCircleIcon className="text-green-500 h-6 w-6 ml-1 mt-0.5" /> : <XCircleIcon className="text-red-500 h-6 w-6 ml-1 mt-0.5" />}
                            </div>
                        </div>

                        <div className="-ml-4 flex items-center justify-left flex-wrap">
                            <div className="ml-4 mt-2">
                                <h3 className="leading-6 font-semibold text-gray-900">
                                    Confidence level: <span className="font-normal">{Math.round(val.confidence * 100)}%</span>
                                </h3>
                            </div>
                        </div>

                        <div className="-ml-4 flex items-center justify-left flex-wrap">
                            <div className="ml-4 mt-2">
                                <h3 className="leading-6 font-semibold text-gray-900">
                                    Correct person ({name}):
                                </h3>
                            </div>
                            <div className="mt-2 flex-shrink-0">
                                {val.correctPerson ? <CheckCircleIcon className="text-green-500 h-6 w-6 ml-1 mt-0.5" /> : <XCircleIcon className="text-red-500 h-6 w-6 ml-1 mt-0.5" />}
                            </div>
                        </div>

                    </div> :
                    <div className="bg-white pb-6 mb-6 mt-5 border-b border-gray-100">
                        <div className="-ml-4 -mt-2 flex items-center justify-left flex-wrap text-gray-400">
                            {val.error ? val.error : `Image might be blurry, or you didn't take a photo of the whole document, or... it might be a selfie!`}
                        </div>
                    </div>}

                <button type="button" onClick={(e) => setValidationResult(null)} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-blue-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Try another
                </button>
            </div>
        )
    }

    const uploadForm = () => {
        return (

            <form className="space-y-6">
                <div>
                    <label htmlFor="name" className="block text-sm font-semibold text-gray-700 mb-3 text-lg">
                        Name on evidence
                    </label>
                    <p className="text-gray-400">Use this to validate whether the evidence belongs to that person.</p>
                    <div className="mt-3 mb-3">
                        <input id="name" onChange={(e) => setName(e.target.value)} name="name" type="name" autoComplete="none" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                </div>
                <div>
                    <label htmlFor="name" className="block text-sm font-semibold text-gray-700 mt-4 mb-3 text-lg">
                        Evidence to check (whole document)
                    </label>
                    <p className="text-gray-400">Images only as this is an OCR test... Load this page on your phone to test with your camera.</p>
                    <div className="mt-3">
                        <input id="evidence" onChange={(e) => setSelectedFile(e.target.files[0])} name="evidence" type="file" accept="image/*" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <p className="text-gray-400 mt-3">Accepted: Apple Wallet, Digital Certificate, Immunisation History Statement.</p>
                </div>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center">
                        <span className="bg-white px-2 text-gray-500">
                            <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path fill="#6B7280" fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                        </span>
                    </div>
                </div>

                <div>
                    <button onClick={submitForm} disabled={!name || !selectedFile} type="button" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Validate <BadgeCheckIcon className="h-4 w-4 mt-0.5 ml-1" />
                    </button>
                </div>
            </form>
        )
    }

    return (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-3">
                {loading ? <p className="font-semibold text-lg text-gray-700">Validating...</p> : validationResult ? displayValidationResult() : uploadForm()}
            </div>
        </div>
    )
}

export default UploadForm;