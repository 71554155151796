import React from 'react';
import './App.css';
import UploadForm from './UploadFile';

export default class UserList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            OCR Vax Validator
          </h2>
        </div>

        <UploadForm />
      </div>
    );
  }
}